exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boka-utbildning-js": () => import("./../../../src/pages/boka-utbildning.js" /* webpackChunkName: "component---src-pages-boka-utbildning-js" */),
  "component---src-pages-campaign-js": () => import("./../../../src/pages/campaign.js" /* webpackChunkName: "component---src-pages-campaign-js" */),
  "component---src-pages-e-handel-js": () => import("./../../../src/pages/e-handel.js" /* webpackChunkName: "component---src-pages-e-handel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-avtalsvillkor-js": () => import("./../../../src/pages/info/avtalsvillkor.js" /* webpackChunkName: "component---src-pages-info-avtalsvillkor-js" */),
  "component---src-pages-info-lan-av-trafikskolans-fordon-pa-prov-js": () => import("./../../../src/pages/info/lan-av-trafikskolans-fordon-pa-prov.js" /* webpackChunkName: "component---src-pages-info-lan-av-trafikskolans-fordon-pa-prov-js" */),
  "component---src-pages-info-resursbank-js": () => import("./../../../src/pages/info/resursbank.js" /* webpackChunkName: "component---src-pages-info-resursbank-js" */),
  "component---src-pages-info-samarbetspartners-js": () => import("./../../../src/pages/info/samarbetspartners.js" /* webpackChunkName: "component---src-pages-info-samarbetspartners-js" */),
  "component---src-pages-info-tack-js": () => import("./../../../src/pages/info/tack.js" /* webpackChunkName: "component---src-pages-info-tack-js" */),
  "component---src-pages-information-din-vag-till-korkortet-js": () => import("./../../../src/pages/information/din-vag-till-korkortet.js" /* webpackChunkName: "component---src-pages-information-din-vag-till-korkortet-js" */),
  "component---src-pages-information-korkortstillstand-js": () => import("./../../../src/pages/information/korkortstillstand.js" /* webpackChunkName: "component---src-pages-information-korkortstillstand-js" */),
  "component---src-pages-information-kunskapsprov-och-korprov-js": () => import("./../../../src/pages/information/kunskapsprov-och-korprov.js" /* webpackChunkName: "component---src-pages-information-kunskapsprov-och-korprov-js" */),
  "component---src-pages-information-undervisningsplan-och-kursplan-js": () => import("./../../../src/pages/information/undervisningsplan-och-kursplan.js" /* webpackChunkName: "component---src-pages-information-undervisningsplan-och-kursplan-js" */),
  "component---src-pages-information-var-personal-js": () => import("./../../../src/pages/information/var-personal.js" /* webpackChunkName: "component---src-pages-information-var-personal-js" */),
  "component---src-pages-information-vara-bilar-js": () => import("./../../../src/pages/information/vara-bilar.js" /* webpackChunkName: "component---src-pages-information-vara-bilar-js" */),
  "component---src-pages-information-vara-utbildningar-js": () => import("./../../../src/pages/information/vara-utbildningar.js" /* webpackChunkName: "component---src-pages-information-vara-utbildningar-js" */),
  "component---src-pages-kontakta-oss-jobba-med-oss-js": () => import("./../../../src/pages/kontakta-oss/jobba-med-oss.js" /* webpackChunkName: "component---src-pages-kontakta-oss-jobba-med-oss-js" */),
  "component---src-pages-kontakta-oss-oppettider-och-information-js": () => import("./../../../src/pages/kontakta-oss/oppettider-och-information.js" /* webpackChunkName: "component---src-pages-kontakta-oss-oppettider-och-information-js" */),
  "component---src-pages-kontakta-oss-praktisera-hos-oss-js": () => import("./../../../src/pages/kontakta-oss/praktisera-hos-oss.js" /* webpackChunkName: "component---src-pages-kontakta-oss-praktisera-hos-oss-js" */),
  "component---src-pages-kontakta-oss-utbildningsanmalan-js": () => import("./../../../src/pages/kontakta-oss/utbildningsanmalan.js" /* webpackChunkName: "component---src-pages-kontakta-oss-utbildningsanmalan-js" */),
  "component---src-pages-omoss-js": () => import("./../../../src/pages/omoss.js" /* webpackChunkName: "component---src-pages-omoss-js" */),
  "component---src-pages-priser-betalningslosningar-js": () => import("./../../../src/pages/priser/betalningslosningar.js" /* webpackChunkName: "component---src-pages-priser-betalningslosningar-js" */),
  "component---src-pages-priser-paketpriser-js": () => import("./../../../src/pages/priser/paketpriser.js" /* webpackChunkName: "component---src-pages-priser-paketpriser-js" */),
  "component---src-pages-priser-styckepris-js": () => import("./../../../src/pages/priser/styckepris.js" /* webpackChunkName: "component---src-pages-priser-styckepris-js" */),
  "component---src-pages-priser-transportstyrelsens-och-trafikverkets-avgifter-js": () => import("./../../../src/pages/priser/transportstyrelsens-och-trafikverkets-avgifter.js" /* webpackChunkName: "component---src-pages-priser-transportstyrelsens-och-trafikverkets-avgifter-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-utbildningar-handledarutbildning-js": () => import("./../../../src/pages/utbildningar/handledarutbildning.js" /* webpackChunkName: "component---src-pages-utbildningar-handledarutbildning-js" */),
  "component---src-pages-utbildningar-individuell-intensivutbildning-js": () => import("./../../../src/pages/utbildningar/individuell-Intensivutbildning.js" /* webpackChunkName: "component---src-pages-utbildningar-individuell-intensivutbildning-js" */),
  "component---src-pages-utbildningar-intensivkurs-tva-veckor-js": () => import("./../../../src/pages/utbildningar/intensivkurs-tva-veckor.js" /* webpackChunkName: "component---src-pages-utbildningar-intensivkurs-tva-veckor-js" */),
  "component---src-pages-utbildningar-intensivutbildning-js": () => import("./../../../src/pages/utbildningar/intensivutbildning.js" /* webpackChunkName: "component---src-pages-utbildningar-intensivutbildning-js" */),
  "component---src-pages-utbildningar-personbilsutbildning-js": () => import("./../../../src/pages/utbildningar/personbilsutbildning.js" /* webpackChunkName: "component---src-pages-utbildningar-personbilsutbildning-js" */),
  "component---src-pages-utbildningar-riskutbildningar-js": () => import("./../../../src/pages/utbildningar/riskutbildningar.js" /* webpackChunkName: "component---src-pages-utbildningar-riskutbildningar-js" */),
  "component---src-pages-utbildningar-teoriundervisning-js": () => import("./../../../src/pages/utbildningar/teoriundervisning.js" /* webpackChunkName: "component---src-pages-utbildningar-teoriundervisning-js" */),
  "component---src-pages-utbildningar-testlektion-js": () => import("./../../../src/pages/utbildningar/testlektion.js" /* webpackChunkName: "component---src-pages-utbildningar-testlektion-js" */)
}

